<template>
  <div class="height-100 max_box">
    <div class="audio-box">
      <audio src="audio/bg.mp3" ref="bgAudio"></audio>
    </div>
    <div class="card animate__animated animate__fadeIn duration" id="card1">
      <p class="data_time">{{ dataTime }}</p>
      <div class="height-100 width-100">
        <video
          src="video/video8.mp4"
          muted
          width="100%"
          height="100%"
          ref="video1"
        ></video>
      </div>
      <div class="logo-box">
        <img src="../assets/img/logo2.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card2"
    >
      <div class="card2_content">
        <p>新股</p>
        <p class="font-blod card2_stock">
          <span v-for="(item, index) in extra_param_2" :key="index"
            >{{ item }} <i v-if="index < extra_param_2.length - 1">丶</i></span
          >
        </p>
        <p>今日启动网上申购</p>
      </div>
      <div class="logo-box">
        <img src="../assets/img/logo2.png" alt="" />
      </div>
      <div class="tips">
        注：相关内容不对各位读者构成任何投资建议，据此操作，风险自担
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      v-for="(item, index) in modelDataList"
      :key="index"
      :id="'card' + (index + 3)"
    >
      <div class="container">
        <div class="title">
          <span class="font-blod font-28 font-weight700">申购指南</span
          ><span class="font-18">{{ dataTime }}</span>
        </div>
        <div class="stock_box">
          <div class="stock_title">
            <span></span>
            <span
              class="font-22 font-weight700 font-blod margin-l-18 margin-r-18"
              >{{ item[0] }}</span
            >
            <span></span>
          </div>
          <div class="stock_box1">
            <p>
              发行价格<span class="num">{{ item[1] }}</span
              >元
            </p>
            <p>
              申购代码：<span class="num">{{ item[2] }}</span>
            </p>
            <p>
              申购数量<span class="num">{{ item[3] }}</span
              >股整数倍 上限<span class="num">{{ item[4] }}</span>
            </p>
            <p>
              发行市盈率：<span class="num">{{ item[5] }}</span
              >倍
            </p>
          </div>
          <div class="stock_box2">
            <p>
              <span>申购日期:</span><span class="num">{{ item[6] }}</span>
            </p>
            <p>
              <span>发行数量(股):</span><span class="num">{{ item[7] }}万</span>
            </p>
            <p>
              <span>申购资金上限(元):</span
              ><span class="num">{{ item[8] }}万</span>
            </p>
            <p>
              <span>网上中签率公告日:</span
              ><span class="num">{{ item[9] }}</span>
            </p>
            <p>
              <span>中签缴款日:</span><span class="num">{{ item[10] }}</span>
            </p>
            <p>
              <span>上市地点:</span><span class="num">{{ item[11] }}</span>
            </p>
          </div>
        </div>
        <div class="logo-box logo-box2">
          <img src="../assets/img/logo2.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      :id="'card' + (modelDataList.length + 3)"
    >
      <div class="width-100 height-100">
        <video
          class="end_video"
          src="video/end.mp4"
          muted
          width="100%"
          height="100%"
          ref="endVideo"
        ></video>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { GetParams } from "../utils/common";
import ApiServe from "@/api/index";
export default {
  name: "Template8",
  data() {
    return {
      dataTime: "",
      device_id: null,
      totalRecordTime: null,
      extra_param_2: [],
      extra_param_3: [],
      modelDataList: [],
    };
  },
  created() {
    this.device_id = GetParams().device_id || null;
    this.getData();
  },
  watch: {
    extra_param_2(val) {
      console.log(val);
      if (val.length > 0) {
        if (this.device_id) {
          setTimeout(() => {
            ApiServe.recordStart({
              model_id: 8,
              device_id: this.device_id,
              record_time: this.totalRecordTime,
              real: 1,
            }).then((res) => {
              this.load();
            });
          }, 5000);
        } else {
          setTimeout(() => {
            this.load();
          }, 3000);
        }
      }
    },
  },
  methods: {
    getData() {
      this.$axios
        .get(process.env.VUE_APP_ONE + "/api/video/videodata", {
          params: { model_id: 8 },
        })
        .then(({ data }) => {
          this.dataTime = data.extra_param_1[0];
          this.extra_param_2 = data.extra_param_2[0].split("、");
          this.extra_param_2.forEach((item, index) => {
            this.modelDataList.push(data["extra_param_" + (4 + index)]);
          });
          // 第一页4秒  第二页2秒 最后一页5秒
          this.extra_param_3 = data.extra_param_3;
          this.totalRecordTime =
            4 +
            2 +
            8 * (this.extra_param_3[0] * 1) +
            this.extra_param_3[0] * 1 +
            5;
        });
    },
    // 切换动画
    changeCard(count = 1) {
      const vdom1 = "#card" + (2 + count);
      const vdom2 = "#card" + (2 + count + 1);
      if (count == 1 && this.extra_param_3[0] * 1 == 1) {
        setTimeout(() => {
          $(vdom1).addClass("none");
          $(vdom2).removeClass("none");
          this.$refs.endVideo.play();
        }, 1000); //间隔
      } else {
        if (count == 1) {
          setTimeout(() => {
            $(vdom1).addClass("animate__fadeOut");
          }, 100);
          setTimeout(() => {
            count++;
            $(vdom1).addClass("none");
            $(vdom2).removeClass("none");
            this.changeCard(count);
          }, 1000);
        } else if (count < this.extra_param_3[0] * 1) {
          setTimeout(() => {
            $(vdom1).addClass("animate__fadeOut");
          }, 8 * 1000);
          setTimeout(() => {
            count++;
            $(vdom1).addClass("none");
            $(vdom2).removeClass("none");
            this.changeCard(count);
          }, 9 * 1000);
        } else {
          setTimeout(() => {
            $(vdom1).addClass("animate__fadeOut");
          }, 8000);
          setTimeout(() => {
            $(vdom1).addClass("none");
            $(vdom2).removeClass("none");
            this.$refs.endVideo.play();
          }, 9000); //间隔
        }
      }
    },
    load() {
      this.$refs.bgAudio.volume = 0.4; //背景音乐音量调节（1为最大）
      this.$refs.bgAudio.play(); //背景音乐播放
      this.$refs.video1.play(); //首页的视频播放
      setTimeout(() => {
        $("#card1").addClass("animate__fadeOut");
      }, 4000);
      setTimeout(() => {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
      }, 5000); //间隔
      setTimeout(() => {
        $("#card2").addClass("animate__fadeOut");
      }, 7000);
      setTimeout(() => {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
      }, 8000); //间隔
      setTimeout(() => {
        $("#card3").addClass("animate__fadeOut");
        this.changeCard();
      }, 16000);
      // 总时间this.totalRecordTime秒
      if (this.device_id) {
        setTimeout(() => {
          ApiServe.recordEnd({
            model_id: 8,
            device_id: this.device_id,
            real: 1,
          });
        }, (this.totalRecordTime + 5) * 1000); //间隔
      }
    },
  },
};
</script>
<style scoped>
.max_box {
  background: #071931;
}
.card {
  background: #071931;
  color: #fff;
  font-size: 16px;
}
.num {
  color: #ff6719;
  font-size: 18px;
}
.data_time {
  position: absolute;
  left: 50%;
  top: 42%;
  transform: translateX(-50%);
}
.card2_content {
  position: absolute;
  left: 15%;
  right: 15%;
  top: 35%;
}
.card2_content p {
  font-size: 18px;
  text-align: center;
  margin-bottom: 8px;
}
.card2_content .card2_stock {
  font-size: 22px;
  text-align: center;
}
.card2_content .card2_stock i {
  font-size: 12px;
}
.tips {
  position: absolute;
  bottom: 13%;
  color: #7470aa;
  width: 100%;
  text-align: center;
  font-size: 12px;
}
.title {
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #ff6719;
}
.stock_box {
  padding: 0 40px;
}
.stock_box .stock_title {
  text-align: center;
  padding: 16px 0;
}
.stock_box .stock_title > span:first-of-type,
.stock_box .stock_title > span:nth-of-type(3) {
  display: inline-block;
  width: 25%;
  height: 1px;
  background: #fff;
  vertical-align: middle;
}
.stock_box1 {
  margin-left: 16px;
}
.stock_box1 p {
  margin-top: 16px;
}
.stock_box2 {
  margin-top: 24px;
  border: 1px solid #5ae5e0;
  background: linear-gradient(180deg, #002f6d 0%, #0f4181 100%);
  opacity: 1;
  padding: 16px 8px;
}
.stock_box2 p {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}
.stock_box2 p:first-of-type {
  margin-top: 0;
}
.stock_box2 p > span:first-of-type {
  display: inline-block;
  width: 150px;
}
.stock_box2 p span:nth-of-type(2) {
  flex: 1;
}
</style>